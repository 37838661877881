












































import { get } from 'lodash';
import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
import TokenUtil from 'global-ui/packages/utils/TokenUtil';
import StorageUtil from 'global-ui/packages/utils/StorageUtil';
import HomePage from '@/api/home/homePage';
import DateUtil from '@/utils/DateUtil';

@Component({
  name: 'FrontPlaceholder',
  components: {
  }
})
export default class FrontPlaceholder extends Vue {
  socket?: WebSocket;
  noticeList: any[] = [];
  parsedList: any[] = [];
  homePage: HomePage = new HomePage();
  visible: boolean = false;
  vis_card: boolean = false;

  get red() {
    return this.noticeList.reduce((pre, cur) => {
      if (cur.noticeType === 'task_time_out') {
        pre++;
      }
      return pre;
    }, 0);
  }

  get yellow() {
    return this.noticeList.reduce((pre, cur) => {
      if (cur.noticeType === 'task_due_date') {
        pre++;
      }
      return pre;
    }, 0);
  }

  get userName() {
    return this.$store.getters.user.name;
  }

  get companyName() {
    return get(this.$store.getters.user, 'tenants[0].name');
  }

  get token() {
    return TokenUtil.getToken();
  }

  get wenhou() {
    let d = new Date();
    if (d.getHours() >= 0 && d.getHours() < 12) {
      return '早安';
    } else if (d.getHours() >= 12 && d.getHours() < 18) {
      return '午安';
    } else {
      return '晚安';
    }
  }

  // 展示卡片信息
  toogleTip() {
    this.visible = false;
    if (this.red > 0 || this.yellow > 0) {
      this.vis_card = true;
    }
  }

  tooglePopover() {
    this.visible = !this.visible;
  }

  socketHandler() {
    let wsUrl = StorageUtil.getLocalStorage('wsUrl');
    let link = new WebSocket(wsUrl);
    this.socket = link;
    link.addEventListener('open', e => {
      if (this.token) {
        link.send(this.token);
      }
    });
    // socket接收到信息
    link.addEventListener('message', e => {
      let data = JSON.parse(e.data);
      // 复制node,放入到格式化之后的列表
      let notice = Object.assign({}, data.notice);
      notice.flowType = 'wait';
      let d = DateUtil.parse(notice.startDate);
      notice.formatedDate = DateUtil.format(d, 'MM/dd HH:mm');
      notice.name = notice.performerName;
      if (data.noticeType === 'task_time_out') {
        this.noticeList.push(data);
        notice.noticeType = data.noticeType;
        this.parsedList.push(notice);
      } else if (data.noticeType === 'task_due_date') {
        this.noticeList.push(data);
        notice.noticeType = data.noticeType;
        this.parsedList.push(notice);
      } else if (data.noticeType === 'task_time_out_delete' || data.noticeType === 'task_due_date_delete') {
        // 如果获取到删除信息,从两个列表中去除
        let index = this.noticeList.findIndex(node => node.notice.id === data.notice.id);
        this.noticeList.splice(index, 1);
        this.parsedList.splice(index, 1);
      }
      // 储存到vuex,左侧菜单可以监听到
      this.$store.commit('home/todo', this.noticeList);
    });
  }

  mounted() {
    // 初始化时获取事项提醒
    this.homePage.getMonitor({ page: { pageIndex: 1, pageSize: 9999 } }).then(res => {
      let today = new Date().getMilliseconds();
      res.data.list.forEach(node => {
        let parsedNode = Object.assign({}, node);
        let d = DateUtil.parse(node.startDate);
        parsedNode.formatedDate = DateUtil.format(d, 'MM/dd HH:mm');
        parsedNode.name = parsedNode.performerName;
        parsedNode.today = true;
        parsedNode.flowType = 'wait';
        if (today > node.dueDate) {
          parsedNode.noticeType = 'task_time_out';
          this.parsedList.push(parsedNode);
          this.noticeList.push({
            notice: node,
            noticeType: 'task_time_out'
          });
        } else {
          parsedNode.noticeType = 'task_due_date';
          this.parsedList.push(parsedNode);
          this.noticeList.push({
            notice: node,
            noticeType: 'task_due_date'
          });
        }
      });
      this.$store.commit('home/todo', this.noticeList);
      this.socketHandler();
    });
  }

  beforeRouterLeave(next) {
    if (this.socket && this.socket.close) {
      this.socket.close();
      this.socket = undefined;
    }
    next();
  }

  /**
   * 切换到后台首页
   */
  goToBackendHome() {
    this.$router.push({ path: '/home' });
  }
}

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "front-placeholder" }, [
    _c(
      "div",
      { staticClass: "lantern-row" },
      [
        _c("div", {
          staticStyle: {
            position: "absolute",
            top: "70%",
            left: "5%",
            color: "white",
            cursor: "pointer",
            "font-weight": "bold",
          },
          on: { click: _vm.goToBackendHome },
        }),
        _c(
          "el-popover",
          {
            attrs: {
              trigger: "hover",
              "popper-class": "popover-wrapper",
              placement: "left",
            },
            model: {
              value: _vm.visible,
              callback: function ($$v) {
                _vm.visible = $$v
              },
              expression: "visible",
            },
          },
          [
            _c("div", { staticClass: "popover-dialog" }, [
              _c("h3", [_vm._v(_vm._s(_vm.$t("lang_process_tips")))]),
              _c(
                "div",
                { staticClass: "popover-content" },
                [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.red || _vm.yellow,
                          expression: "red || yellow",
                        },
                      ],
                      staticClass: "img-container",
                    },
                    [
                      _vm.red > 0
                        ? _c("img", {
                            attrs: {
                              src: require("@/assets/image/front/red.gif"),
                              alt: "",
                            },
                          })
                        : _vm.yellow > 0
                        ? _c("img", {
                            attrs: {
                              src: require("@/assets/image/front/yellow.gif"),
                              alt: "",
                            },
                          })
                        : _vm._e(),
                    ]
                  ),
                  _c(
                    "p",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.red,
                          expression: "red",
                        },
                      ],
                    },
                    [
                      _c(
                        "span",
                        {
                          staticStyle: { cursor: "pointer" },
                          on: { click: _vm.toogleTip },
                        },
                        [_vm._v(_vm._s(_vm.red))]
                      ),
                      _vm._v(_vm._s(_vm.$t("lang_timeout_matters")) + " "),
                    ]
                  ),
                  _c(
                    "p",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.yellow,
                          expression: "yellow",
                        },
                      ],
                    },
                    [
                      _c(
                        "span",
                        {
                          staticStyle: { cursor: "pointer" },
                          on: { click: _vm.toogleTip },
                        },
                        [_vm._v(_vm._s(_vm.yellow))]
                      ),
                      _vm._v(_vm._s(_vm.$t("lang_time_limited_matters")) + " "),
                    ]
                  ),
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.visible = false
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("lang_i_know")))]
                  ),
                ],
                1
              ),
            ]),
            _c(
              "div",
              {
                staticClass: "lantern-img",
                style: { marginRight: _vm.vis_card ? "530px" : null },
                attrs: { slot: "reference" },
                on: { click: _vm.toogleTip },
                slot: "reference",
              },
              [
                _vm.red > 0
                  ? _c("img", {
                      attrs: {
                        src: require("@/assets/image/front/red.gif"),
                        alt: "",
                      },
                    })
                  : _vm.yellow > 0
                  ? _c("img", {
                      attrs: {
                        src: require("@/assets/image/front/yellow.gif"),
                        alt: "",
                      },
                    })
                  : _vm._e(),
              ]
            ),
          ]
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.vis_card,
                expression: "vis_card",
              },
            ],
            staticClass: "card-right__wrapper",
            on: {
              click: function ($event) {
                if ($event.target !== $event.currentTarget) {
                  return null
                }
                _vm.vis_card = false
              },
            },
          },
          [
            _c(
              "div",
              { staticClass: "card-right" },
              [_c("front-list", { attrs: { list: _vm.parsedList } })],
              1
            ),
          ]
        ),
      ],
      1
    ),
    _c("div", { staticClass: "main-img" }, [
      _c("div", { staticClass: "user-info" }, [
        _c("p", [
          _c("span", [
            _vm._v(
              _vm._s(_vm.wenhou) +
                "，" +
                _vm._s(_vm.userName) +
                _vm._s(_vm.$t("lang_work_happy"))
            ),
          ]),
        ]),
        _c("p", [_vm._v(_vm._s(_vm.companyName))]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }